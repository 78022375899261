import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Prism from "prismjs";
import VueBreadcrumbs from "vue-breadcrumbs";
import ComponentFrame from "./components/ComponentFrame";
import DatePicker from "./components/DatePicker";
import DatePickerRange from "./components/DatePickerRange";
import FileUpload from "./components/FileUpload";
import HelpPanel from "./components/HelpPanel";
import PackageHeader from "./components/PackageHeader";
import PackageHeaderDefected from "./components/PackageHeaderDefected";
import PackageNavigation from "./components/PackageNavigation";
import ComponentList from "./components/ComponentList";
import PrimaryNavigation from "./components/PrimaryNavigation";
import Sidebar from "./components/Sidebar";

Vue.component("component-frame", ComponentFrame);
Vue.component("component-list", ComponentList);
Vue.component("date-picker", DatePicker);
Vue.component("date-picker-range", DatePickerRange);
Vue.component("file-upload", FileUpload);
Vue.component("help-panel", HelpPanel);
Vue.component("package-header", PackageHeader);
Vue.component("package-header-defected", PackageHeaderDefected);
Vue.component("package-navigation", PackageNavigation);
Vue.component("primary-navigation", PrimaryNavigation);
Vue.component("sidebar", Sidebar);

Vue.config.productionTip = false;

const vueScrollto = require("vue-scrollto");

import "./scss/ltsa-styles.scss";

Vue.use(vueScrollto);
Vue.use(VueBreadcrumbs, {
  template: `<nav aria-label="breadcrumb" class="d-print-none">
      <ol class="breadcrumb" v-if="$breadcrumbs.length">
        <li class="breadcrumb-item"><router-link to="/"><svg class="icon icon-home"><use xlink:href="/icons/symbol-defs.svg#icon-home"></use></svg></router-link></li>
        <li class="breadcrumb-item" v-if="key < $breadcrumbs.length-1" v-for="(crumb, key) in $breadcrumbs">
        <router-link :to="linkProp(crumb)" :key="key">{{ crumb | crumbText }}</router-link>
        <li class="breadcrumb-item active" v-else style="cursor:default">{{ crumb | crumbText }}</li>
        </li>
      </ol>
    </nav>`
});

var $ = require("jquery");
window.jQuery = $;

require("bootstrap");
require("./../public/js/drawer.js");

Vue.directive("highlight", {
  deep: true,
  bind: function(el, binding) {
    // on first bind, highlight all targets
    let targets = el.querySelectorAll("code");
    targets.forEach(target => {
      Prism.highlightElement(target);
    });
  }
});

Vue.directive("prevDef", {
  deep: true,
  bind: function(el, binding) {
    el.addEventListener("click", function(e) {
      e.preventDefault();
    });
  }
});

Vue.directive("tooltip", {
  deep: true,
  bind: function(el, binding) {
    el.addEventListener("click", function(e) {
      e.preventDefault();
    });

    $(el).tooltip();
  }
});

Vue.directive("popover", {
  deep: true,
  bind: function(el, binding) {
    el.addEventListener("clinpm run ck", function(e) {
      e.preventDefault();
    });

    $(el).popover();
  }
});

Vue.directive("popup", {
  deep: true,
  bind: function(el, binding) {
    el.addEventListener("click", function(e) {
      el.parentNode.classList.toggle("has-popup");
      el.parentNode
        .querySelector(".help-text__popup")
        .classList.toggle("is-visible");
      let targets = el.querySelectorAll(".icon");
      targets.forEach(target => {
        target.classList.toggle("is-visible");
      });
    });
  }
});

Vue.directive("toggleCode", {
  twoWay: true,
  inserted: function(el) {
    el.addEventListener("click", function() {
      if (el.getAttribute("data-text-swap") === el.innerHTML) {
        el.innerHTML = el.getAttribute("data-text-original");
      } else {
        el.setAttribute("data-text-original", el.innerHTML);
        el.innerHTML = el.getAttribute("data-text-swap");
      }

      el.parentNode.querySelector("pre").classList.toggle("is-visible");
    });
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
